import React, { useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InPlaceError from "components/in-place-error/InPlaceError";
import "./select.scss";

interface IOptions {
  options: string[];
  placeholder?: string;
  errorMessage?: string;
  onChange?: (e: string) => void;
  label?: string;
  className?: string;
  initialValue?: string;
}
export const Select = React.forwardRef(
  (
    {
      options,
      placeholder = "Select an option",
      errorMessage,
      className,
      onChange,
      label,
      initialValue = "",
    }: IOptions,
    ref
  ) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const showError = errorMessage ? true : false;
    const value = initialValue;
    if (initialValue !== "" && initialValue === selectedItem) {
      initialValue = "";
    }
    if (initialValue !== "") {
      setSelectedItem(initialValue);
    }

    return (
      <div className={`dropdown-container ${className ? className : ""}`}>
        {label ? (
          <div className="input-label__wrapper">
            <label htmlFor="label" className="input-label">
              {label}
            </label>
          </div>
        ) : (
          ""
        )}
        <div className="dropdown__wrapper">
          <div
            className={showError ? "dropdown dropdown--error" : "dropdown"}
            tabIndex={0}
            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            aria-roledescription="Select the Options"
            role="listbox"
            aria-label="List Box"
          >
            <span>{value !== "" ? selectedItem : placeholder}</span>
            <span data-testid="up-down-icon" className="dropdown-icon">
              {isDropdownVisible ? (
                <FontAwesomeIcon icon={regular("chevron-up")} />
              ) : (
                <FontAwesomeIcon icon={regular("chevron-down")} />
              )}
            </span>
          </div>
          {isDropdownVisible && (
            <div className="dropdown-items">
              {options.map((item, index) => (
                <div
                  key={index}
                  className="select-item"
                  onClick={() => {
                    onChange?.(item);
                    setSelectedItem(item);
                    setIsDropdownVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
          {showError && !isDropdownVisible && (
            <InPlaceError errorPhrase={errorMessage} />
          )}
        </div>
      </div>
    );
  }
);

Select.displayName = "Select";
