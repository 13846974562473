import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./expandable-header.scss";
import { useState } from "react";

interface ExpandableHeaderProps {
  headerText: string;
  component?: React.ReactNode;
}

const ExpandableHeader = ({ headerText, component }: ExpandableHeaderProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded((prevState: boolean) => !prevState);
  };
  return (
    <>
      <div className="expandable-header" onClick={toggleExpand}>
        <span className="header-text">{headerText}</span>
        {isExpanded ? (
          <FontAwesomeIcon icon={regular("angle-up")} className="caret-icon" />
        ) : (
          <FontAwesomeIcon
            icon={regular("angle-down")}
            className="caret-icon"
          />
        )}
      </div>
      {isExpanded && component}
    </>
  );
};

export default ExpandableHeader;
