import "./declarations.scss";
import { useGetDeclarationsQuery } from "./queries";
import { ActionButton } from "pages/application/templates/Summary";
import { NextStepActionResponse } from "pages/application/applicationService";
import QuestionMark from "assets/images/QuestionMark.svg";

const Declarations = ({ isSpouse }: { isSpouse: boolean }) => {
  const {
    hasApplicantDeclarations,
    hasSpouseCoApplicantDeclarations,
    applicantDeclarations,
    spouseCoApplicantDeclarations,
  } = useGetDeclarationsQuery();
  const hasDeclarations = isSpouse
    ? hasSpouseCoApplicantDeclarations
    : hasApplicantDeclarations;
  const declarations = isSpouse
    ? spouseCoApplicantDeclarations
    : applicantDeclarations;
  return (
    <div className="declarations">
      {hasDeclarations && declarations ? (
        <>
          {declarations.map(({ displayName, value, actions }) => (
            <Declaration
              key={displayName}
              declaration={displayName}
              value={value}
              editDetails={actions ? actions[0] : null}
            />
          ))}
        </>
      ) : (
        <div className="no-declarations">
          <img src={QuestionMark} alt="Question Mark" />
          <p>Questions will be answered later</p>
        </div>
      )}
    </div>
  );
};

export const Declaration = ({
  declaration,
  value,
  editDetails,
}: {
  declaration: string;
  value: string;
  editDetails: NextStepActionResponse | null;
}) => {
  return (
    <div className="declaration-wrapper">
        <div className="declaration">{declaration}</div>
        <div className="declaration-value">{value}</div>
      {editDetails && (
        <div className="declaration-edit">
          <ActionButton action="edit" {...editDetails} />
        </div>
      )}
    </div>
  );
};

export default Declarations;
