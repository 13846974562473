import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import Modal from "components/modal/Modal";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { LoadingOverlay } from "components/ui/loading-spinners";
import {
  RegisterData,
  usePostRegistrationMutation,
  DecryptedUser,
  LeadProvider,
} from "../registrationService";
import * as Constant from "utilities/Constant";
import {
  emailAddress,
  firstname,
  lastname,
  loanpurpose,
  phone,
  password_input,
  confirm_password,
} from "utilities/formSchemas";
import { useDispatch } from "react-redux";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { setCredentials, setInquiryId } from "features/authSlice";
import { ErrorType } from "utilities/error";
import nafhub from "assets/images/nafhub-white-background-logo.svg";
import "./digital-lead-registration.scss";
import { Consent } from "components/consent/Consent";
import useDesktopChecker from "hooks/useDesktopChecker";
import PasswordCheckerVersion2 from "components/password-and-confirm-password/passwordcheckerversion2/PasswordCheckerVersion2";
const formSchema = z.object({
  email: emailAddress,
  firstname,
  lastname,
  phone,
  loanpurpose,
  password_input,
  confirm_password,
});
export default function DigitalLeadRegistration({
  decryptedUserDetails,
  showModal = false,
}: {
  readonly decryptedUserDetails?: DecryptedUser;
  readonly showModal?: boolean;
}) {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm({
    resolver: zodResolver(formSchema),
  });
  const isDesktop = useDesktopChecker();
  useEffect(() => {
    if (decryptedUserDetails) {
      const { ex, fn, ln, em, ph, lp, los, id } = decryptedUserDetails;
      if (!ex) {
        if (los) {
          dispatch(setInquiryId(id));
        }
        setValue("firstname", fn);
        setValue("lastname", ln);
        setValue("email", em);
        setValue("phone", ph.replace(/-/g, ""));
        setValue("loanpurpose", lp);
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [decryptedUserDetails]);
  const [error, setError] = useState(0);
  const navigate = useNavigate();

  const [hasPwdError, setHasPwdError] = useState(false);
  const [isCreatePasswordEmpty] = useState(true);
  const [sendRequest, { isSuccess, data, isLoading: isRegistrationLoading }] =
    usePostRegistrationMutation();
  const dispatch = useDispatch();
  const onSubmit = (data: RegisterData) => {
    if (!hasErrors && !hasPwdError) {
      sendRequest(data)
        .unwrap()
        .catch((error: ErrorType) => {
          if (error.status == 409) {
            navigate("/", { replace: true });
          } else {
            setError(500);
          }
        });
    }
  };
  if (isSuccess && data) {
    const hasLosFile = data.hasLosLoanNumber;
    dispatch(
      setCredentials({
        ...data,
        applicationId: "",
        applicationStatus: "",
        hasLosFile,
      })
    );
    navigate("/post-auth", { replace: true });
  }
  const ispasswordFieldModified =
    (dirtyFields.password_input as boolean) || false;
  const hasErrors = !!Object.keys(errors).length;
  if (isRegistrationLoading) return <LoadingOverlay text="Registering..." />;
  const isPasswordFieldEmpty = errors.password_input?.message as string;
  const isDigitalLead =
    decryptedUserDetails?.lpn &&
    decryptedUserDetails?.lpn.toLowerCase() ==
      LeadProvider.DigitalLeads.toLowerCase();

  return (
    <>
      <div className="digital-registration">
        <div className="digital-registration__header">
          <span className="digital-registration__header__thank-you">
            Thank you!
          </span>
          {isDigitalLead ? (
            <>
              <span className="digital-registration__header__subsequent-line">
                {Constant.DigitalLeadGreeting1}
              </span>
              <span className="digital-registration__header__subsequent-line">
                {Constant.DigitalLeadGreeting2}
              </span>
            </>
          ) : (
            <span className="digital-registration__header__greetings">
              {Constant.MicroSiteGreeting}
            </span>
          )}
        </div>
        <div className="digital-registration__form">
          <div className="digital-registration__form__wrapper">
            <img
              className="digital-registration__form__wrapper-logo"
              src={nafhub}
              alt="logo"
            />
            <span className="digital-registration__form__wrapper-info">
              Simply create a password.
            </span>
            {error == 500 && (
              <div className="error__container">
                {Constant.RegistrationError.map((error) => {
                  return (
                    <p key={error} className="error__message">
                      {error}
                    </p>
                  );
                })}
              </div>
            )}
            {isPasswordFieldEmpty && error != 500 && (
              <div className="error__container">
                <p key={error} className="error__message">
                  {Constant.CreatePasswordFieldEmptyError}
                </p>
              </div>
            )}
            {showModal && (
              <Modal
                defaultBody
                defaultBodyLabel="Something went wrong!"
                defaultBodyLabelIcon={regular("triangle-exclamation")}
                defaultBodySubContent={[
                  "Please open the email we sent you and click on the link so that we can try again.",
                  "We apologize for the inconvenience.",
                ]}
                footer={<></>}
              />
            )}
            <Form
              onSubmit={handleSubmit((data) => {
                onSubmit({
                  emailaddress: data.email as string,
                  password: data.password_input as string,
                  firstname: data.firstname as string,
                  lastname: data.lastname as string,
                  loanPurpose: data.loanpurpose as string,
                  phoneNumber: data.phone as string,
                  referralSource: "",
                  referredBy: "someone",
                  inquiryId: decryptedUserDetails?.id,
                  leadProvider: LeadProvider.DigitalLeads,
                });
              })}
            >
              <div className="row--item">
                <FormInput
                  formHandle={register("email")}
                  inputProps={{
                    name: "email",
                    placeholder: "Enter Your Email",
                    label: "Email/Username",
                    errorMessage: errors.email?.message as string,
                    isReadOnly: true,
                  }}
                />
              </div>
              <div className="row--item">
                <FormInput
                  formHandle={register("phone")}
                  inputProps={{
                    name: "phone",
                    placeholder: "Enter Your Mobile Number",
                    label: "Mobile Number",
                    errorMessage: errors.phone?.message as string,
                    isReadOnly: true,
                  }}
                />
              </div>
              <Controller
                name="password_input"
                control={control}
                render={({ field: { onChange } }) => (
                  <PasswordCheckerVersion2
                    onChange={onChange}
                    checkErrors={ispasswordFieldModified}
                    createLabel="Create Password"
                    confirmLabel="Confirm Password"
                    setHasPasswordError={setHasPwdError}
                    inputBorderHighlightForError={
                      isPasswordFieldEmpty
                        ? isCreatePasswordEmpty
                        : !isCreatePasswordEmpty
                    }
                    confirmPasswordErrorMessage={
                      (errors.confirm_password?.message as string)
                        ? Constant.REQUIRED_PASSWORD_CONFIRMATION
                        : ""
                    }
                    setValue={setValue}
                    confirmPasswordInputName="confirm_password"
                    createPasswordPlaceholderName="Create Password"
                    showConfirmPassword
                  />
                )}
              />
              <Button
                title="Continue"
                className={isDesktop ? "btn--squared" : "btn--round"}
              />
            </Form>
          </div>
        </div>
      </div>
      <Consent
        termsOfUseLink={Constant.TERMSOFUSELINK}
        privacyLink={Constant.PRIVACYLINK}
        partnersLink={Constant.PARTNERSLINK}
        agreementLink={Constant.AGREEMENTLINK}
      />
    </>
  );
}
