import { ActionButton, RemoveModal } from "pages/application/templates/Summary";
import { useGetBlocksQuery } from "pages/application/templates/Summary/queries";
import {
  useGetAdditionalPropertiesQuery,
  useGetPrimaryAddressQuery,
} from "./queries";
import { NextStepActionResponse } from "pages/application/applicationService";
import "./property.scss";
import React, { useState } from "react";

const Property = () => {
  const { hasSpouseCoApplicant, next } = useGetBlocksQuery({
    spouseCoApplicantBlockName: "propertyApplicantSpouse",
  });

  return (
    <div className="property-section">
      <Applicant />
      {hasSpouseCoApplicant && <SpouseCoApplicant />}
      <ActionButton action="next" {...next} />
    </div>
  );
};

const Applicant = () => <Properties blockType="propertyApplicant" />;
const SpouseCoApplicant = () => (
  <Properties blockType="propertyApplicantSpouse" />
);

type BlockType = "propertyApplicant" | "propertyApplicantSpouse";

export const Properties = ({ blockType }: { blockType: BlockType }) => {
  const {
    propertyOwnedLabel,
    primaryPropertyLabel,
    addressLine1,
    addressLine2,
  } = useGetPrimaryAddressQuery(blockType);
  const {
    additionalPropertyLabel,
    additionalProperties,
    addAdditionalPropertyAction,
  } = useGetAdditionalPropertiesQuery(blockType);
  return (
    <div className="properties">
      <h3 className="section-subheader">{propertyOwnedLabel}</h3>
      {!!primaryPropertyLabel || additionalProperties.length > 0 ? (
        <>
          {!!primaryPropertyLabel && (
            <PrimaryProperty
              primaryPropertyLabel={primaryPropertyLabel}
              addressLine1={addressLine1}
              addressLine2={addressLine2}
            />
          )}
          {!!additionalPropertyLabel && additionalProperties.length > 0 && (
            <AdditionalProperties
              additionalPropertyLabel={additionalPropertyLabel}
              additionalProperties={additionalProperties}
              addAdditionalPropertyAction={addAdditionalPropertyAction}
            />
          )}
        </>
      ) : (
        <div className="no-properties">
          <p>Not Applicable</p>
        </div>
      )}
      {addAdditionalPropertyAction?.stepActionId &&
        !additionalProperties.length && (
          <div className ="add-new-property-container">
            <ActionButton action="add" {...addAdditionalPropertyAction} />
          </div>
        )}
    </div>
  );
};

const PrimaryProperty = ({
  primaryPropertyLabel,
  addressLine1,
  addressLine2,
}: {
  primaryPropertyLabel: string;
  addressLine1: string;
  addressLine2: string;
}) => {
  return (
    <div className="primary-property">
      <h6 className="section-label">{primaryPropertyLabel}</h6>
      <PropertyDetails
        addressLine1={addressLine1}
        addressLine2={addressLine2}
      />
    </div>
  );
};

export const AdditionalProperties = ({
  additionalPropertyLabel,
  additionalProperties,
  addAdditionalPropertyAction,
}: {
  additionalPropertyLabel: string;
  additionalProperties: {
    addressLine1: string;
    addressLine2: string;
    editAction: NextStepActionResponse;
    removeAction: NextStepActionResponse;
  }[];
  addAdditionalPropertyAction?: NextStepActionResponse;
}) => {
  const [showModal, setShowModal] = useState<boolean[]>([]);
  const toggleModal = (index: number) => {
    setShowModal((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !prevState[index];
      return updatedState;
    });
  };
  return (
    <div className="additional-properties">
      <h6 className="section-label">{additionalPropertyLabel}</h6>
      <div className="additional-properties-wrapper">
        {additionalProperties.map(
          ({ addressLine1, addressLine2, editAction, removeAction }, index) => (
            <React.Fragment key={index}>
              <PropertyDetails
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                editAction={editAction}
                removeAction={removeAction}
                toggleModal={() => toggleModal(index)}
              />
              {showModal[index] && (
                <RemoveModal
                  stepActionId={removeAction.stepActionId}
                  toggleModal={() => toggleModal(index)}
                  label={"Are you sure you want to remove this property?"}
                  subContents={[addressLine1, addressLine2]}
                />
              )}
            </React.Fragment>
          )
        )}
        {addAdditionalPropertyAction?.stepActionId && (
          <ActionButton action="add" {...addAdditionalPropertyAction} />
        )}
      </div>
    </div>
  );
};

export const PropertyDetails = ({
  addressLine1,
  addressLine2,
  editAction,
  removeAction,
  toggleModal,
}: {
  addressLine1: string;
  addressLine2: string;
  editAction?: NextStepActionResponse;
  removeAction?: NextStepActionResponse;
  toggleModal?: () => void;
}) => {
  return (
    <div className="property-details" data-testid="property-details-component">
      <p className="address__line1">{addressLine1}</p>
      <p className="address__line2">{addressLine2}</p>
      {editAction?.stepActionId && (
        <ActionButton action="edit" {...editAction} />
      )}
      {removeAction?.stepActionId && (
        <ActionButton
          action="remove"
          {...removeAction}
          toggleModal={toggleModal}
        />
      )}
    </div>
  );
};

export default Property;
