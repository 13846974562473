import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./files-details.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const iconMappings: { [key: string]: IconDefinition } = {
  jpeg: solid("file-image"),
  jpg: solid("file-jpg"),
  jpe: solid("file-image"),
  doc: solid("file-lines"),
  docx: solid("file-lines"),
  default: solid("file-pdf"),
};

export interface Filedetailsprops {
  fileName: string;
}

export interface Fileuploadprops {
  readonly uploadedby: string;
  readonly createdDate: string;
}

export default function FileDetails({ fileName }: Filedetailsprops) {
  const fileExtension = fileName.split(".").pop() ?? "";
  const iconName =
    iconMappings[fileExtension.toLowerCase()] ?? iconMappings.default;
  return (
    <div className="file-details">
      <div>
        <FontAwesomeIcon className="file-details__icon" icon={iconName} />
      </div>
      <div className="file-details__filename">{fileName}</div>
    </div>
  );
}

export function FileUploadedBy({ uploadedby, createdDate }: Fileuploadprops) {
  return (
    <div className="file-upload">
      <div className="file-upload__uploadedby">{uploadedby}</div>
      <div className="file-upload__createddate">{createdDate}</div>
    </div>
  );
}
