import "./acknowledgements.scss";
import { ActionButton } from "pages/application/templates/Summary";
import Declarations from "./Declarations";
import {
  useGetAcknowledgementsQuery,
  useGetApplicantQuery,
  useGetSpouseCoApplicantQuery,
} from "./queries";
import { NextStepActionResponse } from "pages/application/applicationService";
import ExpandableHeader from "components/expandable-header/ExpandableHeader";

export const Acknowledgements = () => {
  const { hasSpouseCoApplicant, next } = useGetAcknowledgementsQuery();

  return (
    <div
      className="acknowledgements-section"
      data-testid="acknowledgements-section"
    >
      <Applicant />
      {hasSpouseCoApplicant && (
        <>
          <hr />
          <SpouseCoApplicant />
        </>
      )}
      <ActionButton action="next" {...next} />
    </div>
  );
};

export const Applicant = () => {
  const { name, demographics } = useGetApplicantQuery();
  return (
    <div className="acknowledgements-applicant">
      <h6 className="demographics-subheader">{name}</h6>
      <Demographics {...demographics} />
      <ExpandableHeader
        headerText="View Declarations"
        component={<Declarations isSpouse={false} />}
      />
    </div>
  );
};

export const SpouseCoApplicant = () => {
  const { name, demographics } = useGetSpouseCoApplicantQuery();
  return (
    <div className="acknowledgements-applicant">
      <h6 className="demographics-subheader">{name}</h6>
      <Demographics {...demographics} />
      <ExpandableHeader
        headerText="View Declarations"
        component={<Declarations isSpouse />}
      />
    </div>
  );
};

export const Demographics = ({
  ethnicityLabel,
  ethnicity,
  raceLabel,
  race,
  genderLabel,
  gender,
  editDetails,
}: {
  ethnicityLabel: string;
  ethnicity: string;
  raceLabel: string;
  race: string;
  genderLabel: string;
  gender: string;
  editDetails: NextStepActionResponse;
}) => {
  return (
    <div className="demographics">
      <div className="details">
        <span className="details-label">{ethnicityLabel}:</span>
        <span className="details-value">{ethnicity}</span>
      </div>
      <div className="details">
        <span className="details-label">{raceLabel}: </span>
        <span className="details-value">{race}</span>
      </div>
      <div className="details">
        <span className="details-label">{genderLabel}: </span>
        <span className="details-value">{gender}</span>
      </div>
      <div>
        <ActionButton action="edit" {...editDetails} />
      </div>
    </div>
  );
};

export default Acknowledgements;
