import { ActionButton } from "pages/application/templates/Summary";
import { useGetBlocksQuery } from "pages/application/templates/Summary/queries";
import { useGetApplicantQuery, useGetSpouseCoApplicantQuery } from "./queries";
import { NextStepActionResponse } from "pages/application/applicationService";
import "./credit.scss";

const Credit = () => {
  const { hasSpouseCoApplicant, next } = useGetBlocksQuery({
    spouseCoApplicantBlockName: "creditSpouseCoApplicant",
  });

  return (
    <div className="credit-section">
      <Applicant />
      {hasSpouseCoApplicant && <SpouseCoApplicant />}
      <ActionButton action="next" {...next} />
    </div>
  );
};

const Applicant = () => {
  const { name, details } = useGetApplicantQuery();
  return (
    <div className="credit-applicant">
      <h3 className="section-subheader">{name}</h3>
      <Details {...details} />
    </div>
  );
};

export const SpouseCoApplicant = () => {
  const { name, details } = useGetSpouseCoApplicantQuery();
  return (
    <div className="credit-spouse-co-applicant">
      <h3 className="section-subheader">{name}</h3>
      <Details {...details} />
    </div>
  );
};

export const Details = ({
  dateOfBirthLabel,
  dateOfBirth,
  socialSecurityNumberLabel,
  socialSecurityNumber,
  editDetails,
}: {
  dateOfBirthLabel: string;
  dateOfBirth: string;
  socialSecurityNumberLabel: string;
  socialSecurityNumber: string;
  editDetails: NextStepActionResponse;
}) => {
  const maskSSN = (ssn: string) => {
    const lastFour = ssn.slice(-4);
    return `*** ** ${lastFour}`;
  };
  return (
    <div className="details-wrapper">
      <div className="date-of-birth">
        <h6 className="segment-label">{dateOfBirthLabel}:</h6>
        <p className="details-value">{dateOfBirth}</p>
      </div>
      <div className="social-security-number">
        <h6 className="segment-label">{socialSecurityNumberLabel}:</h6>
        <p className="details-value">{maskSSN(socialSecurityNumber)}</p>

        <div className="edit-details-credit">
          <ActionButton action="edit" {...editDetails} />
        </div>
      </div>
    </div>
  );
};

export default Credit;
