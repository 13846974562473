import React from "react";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { initFullStory } from "utilities/full-story";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import "./index.scss";

//For dynamic config variables importing - making window variable typesafe to access env key
//we access window.env in runtime so to avoid typechecking, declaring key globally
declare global {
  export interface EnvironmentVariable {
    REACT_APP_BASE_BFF_WEB_URL: string;
    APPLICATION_ID: string;
    ENABLE_FULL_STORY: boolean;
    FULLSTORY_ORG_ID: string;
    DISPLAY_STEP_CODE?: boolean;
    SHOW_DASHBOARD: boolean;
    APPINSIGHTS_CONNECTION_STRING: string;
  }
  export interface Window {
    env: EnvironmentVariable;
  }
}

initFullStory();
const root = ReactDOM.createRoot(document.getElementById("root")!);
const persistor = persistStore(store);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
