import "./profile.scss";
import { useState } from "react";
import {
  useGetApplicantQuery,
  useGetNonSpouseCoApplicantQuery,
  useGetSpouseCoApplicantQuery,
} from "./queries";
import { ActionButton, RemoveModal } from "pages/application/templates/Summary";
import { useGetBlocksQuery } from "pages/application/templates/Summary/queries";
import ToolTip from "components/ui/tooltip/ToolTip";
import useDesktopChecker from "hooks/useDesktopChecker";

const Profile = () => {
  const {
    hasSpouseCoApplicant,
    hasNonEmptySpouseCoApplicant,
    hasNonSpouseCoApplicant,
    next,
  } = useGetBlocksQuery({
    spouseCoApplicantBlockName: "profileSpouseCoApplicant",
  });

  return (
    <div className="profile-section">
      <Applicant />
      {hasSpouseCoApplicant && (
        <SpouseCoApplicant
          hasSpouseCoApplicant={hasNonEmptySpouseCoApplicant}
        />
      )}
      {hasNonSpouseCoApplicant && <NonSpouseCoApplicant />}
      <ActionButton action="next" {...next} />
    </div>
  );
};

const Applicant = () => {
  const { applicantLabel, applicantName, primaryAddress, mailingAddress } =
    useGetApplicantQuery();

  return (
    <div className="profile-applicant section-border">
      <h3 className="section-subheader applicant-label">{applicantLabel}</h3>
      <p className="applicant-name">{applicantName}</p>
      <div className="borrower-addresses">
        <Address {...primaryAddress} />
        <Address {...mailingAddress} />
      </div>
    </div>
  );
};

const SpouseCoApplicant = ({
  hasSpouseCoApplicant,
}: {
  hasSpouseCoApplicant: boolean;
}) => {
  const {
    spouseCoApplicantLabel,
    spouseCoApplicantName,
    spouseCoApplicantEmail,
    primaryAddress,
    mailingAddress,
    editDetails,
    remove,
    addSpouseAction,
  } = useGetSpouseCoApplicantQuery();
  const [show, setShow] = useState<boolean>(false);
  const toggleModal = () => setShow(!show);
  return (
    <div className="profile-spouse-co-applicant section-border">
      <h3 className="section-subheader applicant-label">
        {spouseCoApplicantLabel}
      </h3>
      {hasSpouseCoApplicant ? (
        <>
          <div className="applicant-demographics">
            <p className="applicant-name">{spouseCoApplicantName}</p>
            <p className="applicant-email">{spouseCoApplicantEmail}</p>
            {editDetails && <ActionButton action="edit" {...editDetails} />}
            {remove && (
              <ActionButton
                action="remove"
                {...remove}
                toggleModal={toggleModal}
              />
            )}
            {show && (
              <RemoveModal
                stepActionId={remove?.stepActionId}
                toggleModal={toggleModal}
                label={"Are you sure you want to remove Co-Borrower?"}
                subContents={[
                  `Co-Borrower: ${spouseCoApplicantName}`,
                  `Email: ${spouseCoApplicantEmail}`,
                ]}
              />
            )}
          </div>
          <div className="spouse-addresses">
            <Address {...primaryAddress} />
            <Address {...mailingAddress} />
            {addSpouseAction && (
              <ActionButton action="add" {...addSpouseAction} />
            )}
          </div>
        </>
      ) : (
        <div className="no-co-applicants">
          <p>Spouse not added</p>
          {addSpouseAction && (
            <ActionButton action="add" {...addSpouseAction} />
          )}
        </div>
      )}
    </div>
  );
};

const NonSpouseCoApplicant = () => {
  const { coApplicantLabel, coApplicants, addCoApplicantAction } =
    useGetNonSpouseCoApplicantQuery();
  const [showModal, setShowModal] = useState<boolean[]>([]);
  const toggleModal = (index: number) => {
    setShowModal((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !prevState[index];
      return updatedState;
    });
  };

  return (
    <div className="profile-co-applicant section-border">
      <h3 className="section-subheader applicant-label">{coApplicantLabel}</h3>
      {coApplicants.length > 0 ? (
        <div className="co-borrower-applicant-demographics">
          <div className="co-borrower-applicant-demographics__section1">
            {coApplicants.map(
              ({ name, email, editAction, removeAction }, index) => (
                <div key={name}>
                  <p className="applicant-name">{name}</p>
                  <p className="applicant-email" title={email}>
                    {email}
                  </p>
                  <ActionButton action="edit" {...editAction} />
                  <ActionButton
                    action="remove"
                    {...removeAction}
                    toggleModal={() => toggleModal(index)}
                  />
                  {showModal[index] && (
                    <RemoveModal
                      stepActionId={removeAction.stepActionId}
                      toggleModal={() => toggleModal(index)}
                      label={"Are you sure you want to remove Co-Borrower?"}
                      subContents={[`Co-Borrower: ${name}`, `Email: ${email}`]}
                    />
                  )}
                </div>
              )
            )}
          </div>
          <div className="co-borrower-applicant-demographics__section2">
            {addCoApplicantAction && (
              <ActionButton action="add" {...addCoApplicantAction} />
            )}
          </div>
        </div>
      ) : (
        <div className="no-co-applicants">
          <p>Co-Borrower not added</p>
          <div className="no-co-applicants__title">
            {addCoApplicantAction && (
              <ActionButton action="add" {...addCoApplicantAction} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const Address = ({
  addressLabel,
  addressLine1,
  addressLine2,
  stepActionId,
  label,
}: AddressModel) => {
  const isDesktop = useDesktopChecker();
  return (
    <div className="address">
      <h6 className="section-label address__name">{addressLabel}</h6>
      {isDesktop ? (
        <p className="address__line1">
          {addressLine1}
          <span className="tooltip-wrapper">
            <ToolTip content={addressLine1} position="up" />
          </span>
        </p>
      ) : (
        <p className="address__line1">{addressLine1}</p>
      )}

      <p className="address__line2">{addressLine2}</p>
      <ActionButton action="edit" stepActionId={stepActionId} label={label} />
    </div>
  );
};

export interface AddressModel {
  addressLabel: string;
  addressLine1: string;
  addressLine2: string;
  stepActionId: string;
  label: string;
}

export default Profile;
